<template>
    <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800"> TRIP DETAILS</h1>
        <div class="card shadow mb-4">
                    <div class="card-header py-3">
                        <h6 class="m-0 font-weight-bold text-primary">
                            TRIP INFORMATION - #{{tripDetail.invoiceNumber}}
                            <label v-if="tripDetail.isExtraDiscount" class="text-danger">
                                ( Discount applied on this ride, {{tripDetail.discount.totalTrips === 4 ? `You got ${tripDetail.discount.discountValue || '$50'} discount on your 4th Ride` : `User got ${tripDetail.discount.discountValue || '10%'} discount on first Ride.`}} )
                            </label>
                        </h6>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="info_wrp">
                                    <ul>
                                        <li><span>Source</span>: {{tripDetail.startLocationAddr}}</li>
                                        <li v-if="tripDetail.stopLocationAddr"><span>Stop</span>: {{tripDetail.stopLocationAddr}}</li>
                                        <li><span>Distance</span>: {{tripDetail.distance || 'N/A'}} {{tripDetail.unit}}</li>
                                        <li><span>Cost</span>: ${{Math.round(tripDetail.cost)}}</li>
                                        <li><span>Gratuity Amount</span>: ${{Math.round(tripDetail.tipAmount)}}</li>
                                        
                                        <li v-if="tripDetail.meet"><span>Meet & Greet</span>: ${{Math.round(tripDetail.meetAmount)}}</li>
                                        
                                        <li><span>Schedule Date</span>: {{$root.$options.filters.formatDate(tripDetail.scheduleDate)}}</li>
                                        <li><span>Passenger</span>: {{tripDetail.noOfPassenger}}</li>
                                        <li><span>Ride Type</span>: {{tripDetail.rideType}}</li>
                                        <li><span>Ride Confirmed</span>: {{$root.$options.filters.formatDate(tripDetail.tripConfirmedAt, "MM-DD-YYYY hh:ss")}}</li>
                                        <li><span>Additional Info</span>: {{tripDetail.additionInfo}}</li>
                                        <li><span>Occasion</span>: {{occasion[tripDetail.occasion] || 'N/A'}}</li>
                                    </ul>
                                    <h6>USER INFORMATION </h6>
                                    <ul>
                                        <li><span>Name</span>: {{tripDetail.customerRefId && tripDetail.customerRefId.firstName}} {{tripDetail.customerRefId && tripDetail.customerRefId.lastName}}</li>
                                        <li><span>Email</span>: {{tripDetail.customerRefId && tripDetail.customerRefId.email}}</li>
                                        
                                    </ul>
                                    <h6>PAYMENT INFORMATION </h6>
                                    <ul>
                                        <li><span>Actual Cost</span>: ${{Math.round(tripDetail.actualCost)}}</li>
                                        <li><span>Method</span>: {{tripDetail.paymentMethod}}</li>
                                        <li><span>PaymentRefNo</span>: {{tripDetail.paymentRefNo}}</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="info_wrp">
                                    <ul>
                                        <li><span>Destination</span>: {{tripDetail.endLocationAddr}}</li>
                                        <li><span>Time(min)</span>: {{tripDetail.estimatedTime || 'N/A'}}</li>
                                        <li><span>Hours</span>: {{tripDetail.selectedHour || 'N/A'}}</li>
                                        <li><span>Created At</span>: {{$root.$options.filters.formatDate(tripDetail.tripCreatedAt)}}</li>
                                        <li><span>Schedule Time</span>: {{tripDetail.scheduleTime}}</li>
                                        <li><span>Bags</span>: {{tripDetail.noOfBags}}</li>
                                        <li><span>Status</span>: {{$root.$options.filters.tripStatus(tripDetail.tripStatus)}}</li>
                                        <li><span>Trip Completed</span>: {{$root.$options.filters.formatDate(tripDetail.tripEndedAt, "MM-DD-YYYY hh:ss")}}</li>
                                    </ul>
                                    <h6></h6>
                                    <ul>
                                        <li><span>Phone</span>: {{tripDetail.customerRefId && tripDetail.customerRefId.mobileNumber}}</li>
                                        
                                    </ul>

                                    <ul>
                                        
                                        <li><span>Payment Status</span>: {{tripDetail.paymentStatus}}</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="btn_sec">
                                    <a v-if="tripDetail.tripStatus === 'FindingDrivers'" href="javascript:void(0)" @click="cancelTrip()" class="btn btn-primary btn-icon-split">
                                        <span class="icon text-white-50">
                                            <i class="fa fa-unlock"></i>
                                        </span>
                                        <span class="text">Cancel</span>
                                    </a>
                                    <router-link :to="{name: 'trips'}" class="btn btn-info btn-icon-split">
                                        <span class="icon text-white-50">
                                            <i class="fa fa-angle-left"></i>
                                        </span>
                                        <span class="text">Back</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
</template>

<script>
import { request } from "../../utils/api";
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            tripDetail: '',
            tripId: this.$route.params.tripId,
            occasion: ['Airport Transfer', 'Graduation', 'Wedding Send-off', 'Brithday/quinceañera', 'Bachelor/Bachelorette Party', 'Other'],
        }
    },
    computed:{
		...mapGetters(['booking', 'isLoggedIn', 'userId', 'userToken']),        
	},
    methods:{
        async getCarTypes(){
            const resp = await request({
                method: 'get',
                url: `/api/v1/trip/user/tripdetails/${this.tripId}`,
                headers: {
                    authorization: this.userToken,
                    customerid: this.userId
                }
            })

            if (resp.remote === "success") {
                this.tripDetail = resp.data;
            }else{
                
            }
        },
        async cancelTrip(){
            if(window.confirm('Are you sure, want to cancel the trip?')){
                const resp = await request({
                    method: 'post',
                    url: `/api/v1/trip/customercancel`,
                    data: {
                        tripId: this.tripId
                    },
                    headers: {
                        authorization: this.userToken,
                        customerid: this.userId
                    }
                })

                if (resp.remote === "success") {
                    this.$router.push({name: 'trips'})
                }else{
                    
                }
            }
        },
    },
    mounted(){
        this.getCarTypes()
    }
}
</script>

<style>

</style>
