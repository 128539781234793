<template>
    <section class="check-out-area">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="check-out">
                        <div class="top">
                            <div class="thanks">
                                <!-- <span><img src="images/icon/thanks.png" alt=""></span> -->
                                Review Your Ride
                            </div>
                        </div>
                        <div class="middle">
                            <h2>RESERVATION BASIC INFORMATION</h2>
                            <ul class="summary-bar">
                                <li>
                                    <div class="info">
                                        Pick Up Address
                                    </div>
                                    <p>{{pickUpLocation}}</p>
                                </li>
                                <li v-if="tripType == 'onewaytrip' && Object.keys(booking.location.stopLocation).length">
                                    <div class="info">
                                        Stop Address
                                    </div>
                                    <p>{{stopLocation}}</p>
                                </li>
                                <li v-if="tripType == 'onewaytrip'">
                                    <div class="info">
                                        Drop Off Address
                                    </div>
                                    <p>{{dropOffLocation}}</p>
                                </li>
                                <li>
                                    <div class="info">
                                        Pick Up Date
                                    </div>
                                    <p>{{pickUpDate}}</p>
                                </li>
                                <li>
                                    <div class="info">
                                        Pick Up Time
                                    </div>
                                    <p>{{pickUpTime}}</p>
                                </li>

                                <li v-if="booking.location.isReturnWay" class="mt-5">
                                    <h3 class="mb-3">Round Trip Information</h3>
                                    <ul>
                                        <li>
                                            <div class="info">
                                                Pick Up Address
                                            </div>
                                            <p>{{dropOffLocation}}</p>
                                        </li>
                                        <li v-if="tripType == 'onewaytrip' && Object.keys(booking.location.stopLocation).length">
                                            <div class="info">
                                                Stop Address
                                            </div>
                                            <p>{{stopLocation}}</p>
                                        </li>
                                        <li v-if="tripType == 'onewaytrip'">
                                            <div class="info">
                                                Drop Off Address
                                            </div>
                                            <p>{{pickUpLocation}}</p>
                                        </li>
                                        <li>
                                            <div class="info">
                                                Pick Up Date
                                            </div>
                                            <p>{{returnPickUpDate}}</p>
                                        </li>
                                        <li>
                                            <div class="info">
                                                Pick Up Time
                                            </div>
                                            <p>{{returnPickUpTime}}</p>
                                        </li>
                                    </ul>
                                </li>

                                <li v-if="tripType == 'onewaytrip'">
                                    <div class="info">
                                        Duration
                                    </div>
                                    <p>About {{booking.estimates.estimatedTime}} Min's – Distance: {{booking.estimates.distance}} Miles </p>
                                </li>

                                <li v-if="tripType == 'hourtrip'">
                                    <div class="info">
                                        Selected Hours
                                    </div>
                                    <p>{{selectedHour}} </p>
                                </li>
                            </ul>
                        </div>
                        <div class="bottom">
                            <h2>SELECTED CAR</h2>
                            <p>{{booking.carType.carType}}</p>
                            <div class="car-choose">
                                <!-- <img :src="booking.carType.carImage" alt=""> -->
                                <VueSlickCarousel v-bind="settings">
                                    <img v-for="(img, index) in booking.carType.carImage"
                                        :key="index"
                                        :src="img.imageUrl" alt="">
                                </VueSlickCarousel>
                            </div>
                            <p>Price - ${{ booking.estimates.tCost }}</p>

                            <hr/>

                            <div v-if="booking.carType.classType == 'standard' && filterCarOpt" class="offerCars">
                                <h2>Upgrade to First Class by paying ${{ recommendedPrice - booking.estimates.tCost}} extra</h2>
                                <p>{{filterCarOpt.carType }} - {{filterCarOpt.categoryId.categoryName }}</p>
                                <div class="car-choose">
                                    <!-- <img :src="booking.carType.carImage" alt=""> -->
                                    <VueSlickCarousel v-bind="settings">
                                        <img v-for="(img, index) in filterCarOpt.carImage"
                                            :key="index"
                                            :src="img.imageUrl" alt="">
                                    </VueSlickCarousel>
                                </div>
                                <p class="pb-2">Price - ${{ recommendedPrice }}</p>
                                
                                <button v-if="booking.location.type == env.VUE_APP_HOUR_TRIP" type="button" @click="chooseRecommendedHourCar()"> Choose</button>
                                <button v-else type="button" @click="chooseRecommendedCar()"> Choose</button>
                            </div>

                            <div class="couponSection mt-5" v-if="!booking.additionInfo.isExtraDiscount">
                                <form method="post" accept-charset="utf-8" @submit.prevent class="couponForm">
                                    <div class="one-half one-half2 number-pass">
                                        <div class="">
                                            <input type="text" placeholder="Enter Coupon" v-model="couponCode" :disabled="Object.keys(coupon).length > 0"/>
                                            <button v-if="!Object.keys(coupon).length" type="button" @click="applyCoupon()">Apply</button>
                                            <button type="button" @click="removeCoupon()" v-if="Object.keys(coupon).length">Remove</button>
                                            <label v-if="couponError" class="text-danger d-block">{{ couponError }}</label>
                                            
                                            <p v-if="Object.keys(coupon).length" class="text-success">Coupon code <b>{{ couponCode }}</b> applied and payable amount is <span class="line">${{ booking.estimates.tCost }}</span> ${{ coupon.priceAfterCouponApplied }}</p>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <!-- referral code -->
                            <div class="couponSection mt-5" >
                                <form method="post" accept-charset="utf-8" @submit.prevent class="couponForm">
                                    <div class="one-half one-half2 number-pass">
                                        <div class="">
                                            <input type="text" placeholder="Enter Referral Code" v-model="referralCode" :disabled="Object.keys(referral).length > 0"/>
                                            <button  type="button" v-if="!Object.keys(referral).length" @click="applyReferralCode()">Use</button>
                                            <button type="button" @click="removeReferral()" v-if="Object.keys(referral).length">Remove</button>
                                            <label v-if="referralError" class="text-danger d-block">{{ referralError }}</label>
                                            
                                            <p v-if="Object.keys(referral).length" class="text-success">Referral code <b>{{ referralCode }}</b> applied and you will get a $25 discount</p>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div class="action pt-5 pb-5" style="color: #1e1e1e">
                                <input type="checkbox" v-model="terms"/> I agreed to all <a href="#/terms" target="_black">terms & condition</a>
                                <br/>
                                <input type="checkbox" v-model="meetGreet"/> The Meet and Greet service cost $25, where the driver will meet you at the baggage claim area.
                                <br/>
                                <span v-if="customer.referralPoints > 0 ">
                                    <input type="checkbox" v-model="redeemReferral"/> You have ${{customer.referralPoints}} referral points, but only 25 points can be used for your current order. Other discounts or coupons are not applicable.
                                </span>

                            </div>
                                <button v-if="tripType == 'onewaytrip'" :disabled="!terms" type="button" @click="confirmRide()"> Confirm Your Ride</button>
                                <button v-if="tripType == 'hourtrip'"  :disabled="!terms"  type="button" @click="confirmHourRide()"> Confirm Your Ride</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from "vuex";
import { request } from "../../utils/api";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    components: { VueSlickCarousel },
    data(){
        return {
            pickUpLocation: '',
            dropOffLocation: '',
            stopLocation: '',
            selectedHour: '',
            pickUpDate: '',
            pickUpTime: '',
            returnPickUpDate: '',
            returnPickUpTime: '',
            tripType: '',
            terms: false,
            meetGreet: false,
            redeemReferral: false,
            carTypes: '',
            filterCarOpt: '',
            totalTrips: '',
            recommendedPrice: '',
            env: process.env,
            priceWithTip: '',
            couponCode: '',
            couponError: '',
            couponDiscountPrice: '',

            referralCode: '',
            referralError: '',
            referralDiscountPrice: '',
            settings: {
				"autoplay": true,
				"edgeFriction": 0.35,
				"fade": true,
  				"infinite": true,
				"speed": 500,
				"slidesToShow": 1,
				"cssEase": "linear",
				"slidesToScroll": 1
			}
        }
    },
    computed:{
		...mapGetters(['booking', 'coupon', 'isLoggedIn', 'customer', 'referral', 'userId', 'userToken']),
        
	},
    methods:{
        async confirmRide () {
            const { additionInfo, carType, cardId, estimates, location } = this.booking;
            const bookingInfo = {
                "trip_type" : "instant",
                "rideType" : location.type,
                "pricingType" : "global",
                "prefDriver" : "none",
                "unit" : estimates.unit,
                "distance" : estimates.distance,
                "estimatedTime" : estimates.estimatedTime,
                "estimatedCost" : estimates.estimatedCost,
                "carTypeRequired" : carType._id,
                "isRideMinimum" : carType.isRideMinimum,
                "minimumDistance" : carType.minimumDistance,
                "paymentMethod" : "Stripe",
                "paymentSourceRefNo" : cardId,
                "source" : {
                    "startLocationAddr" : location.pickUpLocation.formattedAddress,
                    "startLocation" : {"lat": location.pickUpLocation.lat, "lng": location.pickUpLocation.lng},
                    "addressComponents" : {}
                    
                },
                "destination" : {
                    "endLocationAddr" : location.dropOffLocation.formattedAddress,
                    "endLocation" : {"lat": location.dropOffLocation.lat,"lng": location.dropOffLocation.lng},
                    "addressComponents" : {}
                },
                "scheduleTimezone" : "America/Los_Angeles",
                "scheduleDate": location.pickUpDate,
                "scheduleTime": location.pickUpTime,
                noOfBags: additionInfo.numberBags,
                noOfPassenger: additionInfo.numberPassenger,
                additionInfo: additionInfo.additionalInfo,
                occasion: additionInfo.occasion,
                meetGreet: this.meetGreet,
                cleaningFee: estimates.cleaningFee,
                isRoundTrip: location.isReturnWay,
                redeemReferral: this.redeemReferral,
                seats: additionInfo.seats,
                isAccessible: additionInfo.isAccessible
            }

            if(location.isReturnWay){
                bookingInfo.scheduleReturnDate = location.returnPickUpDate;
                bookingInfo.scheduleReturnTime = location.returnPickUpTime;
            }

            bookingInfo.isExtraDiscount = false;
            if(additionInfo.isExtraDiscount){
                bookingInfo.discount = additionInfo.discountDetails;
                bookingInfo.isExtraDiscount = additionInfo.isExtraDiscount;
            }

            if(Object.keys(location.stopLocation).length){
                bookingInfo.stop = {
                    "endLocationAddr" : location.stopLocation.formattedAddress,
                    "endLocation" : {"lat": location.stopLocation.lat,"lng": location.stopLocation.lng},
                    "addressComponents" : {}
                }
            }

            if(Object.keys(this.coupon).length){
                bookingInfo.promoCode = this.coupon.coupon
            }

            if(Object.keys(this.referral).length){
                bookingInfo.referralCode = this.referral.referralCode
            }

            if(additionInfo.isExtraDiscount || Object.keys(this.coupon).length || Object.keys(this.referral).length){
                bookingInfo.redeemReferral = false
            }

            const resp = await request({
                method:'POST',
                url: '/api/v1/trip/addtrip',
                data: bookingInfo,
                headers: {
                    authorization: this.userToken,
                    customerid: this.userId
                }
            });
            if(resp.remote == 'success'){
                this.$limotoast.success('Trip added!');
                this.$router.push({name: 'thanks'});
                this.$store.commit('clearBooking');
            }else{message
                this.$limotoast.error(resp.errors);
            }
        },
        async confirmHourRide () {
            const { additionInfo, carType, cardId, location, estimates } = this.booking;
            const bookingInfo = {
                "trip_type" : "instant",
                "rideType" : location.type,
                "pricingType" : "global",
                "prefDriver" : "none",
                "carTypeRequired" : carType._id,
                "paymentMethod" : "Stripe",
                "paymentSourceRefNo" : cardId,
                "source" : {
                    "startLocationAddr" : location.pickUpLocation.formattedAddress,
                    "startLocation" : {"lat": location.pickUpLocation.lat, "lng": location.pickUpLocation.lng},
                    "addressComponents" : {}
                    
                },
                selectedHour: location.selectedHour,
                "scheduleTimezone" : "America/Los_Angeles",
                "scheduleDate": location.pickUpDate,
                "scheduleTime": location.pickUpTime,
                noOfBags: additionInfo.numberBags,
                noOfPassenger: additionInfo.numberPassenger,
                additionInfo: additionInfo.additionalInfo,
                occasion: additionInfo.occasion,
                meetGreet: this.meetGreet,
                cleaningFee: estimates.cleaningFee,
                redeemReferral: this.redeemReferral,
                seats: additionInfo.seats,
                isAccessible: additionInfo.isAccessible
            }

            bookingInfo.isExtraDiscount = false;
            if(additionInfo.isExtraDiscount){
                bookingInfo.discount = additionInfo.discountDetails;
                bookingInfo.isExtraDiscount = additionInfo.isExtraDiscount;
            }

            if(Object.keys(this.coupon).length){
                bookingInfo.promoCode = this.coupon.coupon
            }

            if(Object.keys(this.referral).length){
                bookingInfo.referralCode = this.referral.referralCode
            }

            if(additionInfo.isExtraDiscount || Object.keys(this.coupon).length || Object.keys(this.referral).length){
                bookingInfo.redeemReferral = false
            }

            const resp = await request({
                method:'POST',
                url: '/api/v1/trip/addtrip',
                data: bookingInfo,
                headers: {
                    authorization: this.userToken,
                    customerid: this.userId
                }
            });
            if(resp.remote == 'success'){
                this.$limotoast.success('Trip added!');
                this.$router.push({name: 'thanks'});
                this.$store.commit('clearBooking');
            }else{
                this.$limotoast.error(resp.errors);
            }
        },
        async getRecomendedCars () {            
            const jsonDataPass = {
                source: {
                    startLocationAddr: this.booking.location.pickUpLocation.formattedAddress,
                    startLocation: {
                        lat: this.booking.location.pickUpLocation.lat,
                        lng: this.booking.location.pickUpLocation.lng,
                    },
                    addressComponents: {},
                },
                destination: {
                    endLocationAddr: this.booking.location.dropOffLocation.formattedAddress,
                    endLocation: {
                        lat: this.booking.location.dropOffLocation.lat,
                        lng: this.booking.location.dropOffLocation.lng,
                    },
                    addressComponents: {},
                },
                isReturnWay: this.booking.location.isReturnWay
            };

            if (Object.keys(this.booking.location.stopLocation).length) {
                jsonDataPass.stop = {
                stopLocationAddr: this.booking.location.stopLocation.formattedAddress,
                stopLocation: {
                    lat: this.booking.location.stopLocation.lat,
                    lng: this.booking.location.stopLocation.lng,
                },
                addressComponents: {},
                };
            }

            const resp = await request({
                method: "POST",
                url: "/api/v1/trip/estimatedcostall",
                data: jsonDataPass,
            });
            
            if (resp.remote == "success") {
                this.carTypes = resp.data.map((e) => {
                    return { estimatedCost: e.estimatedCost, isRideMinimum: e.isRideMinimum, minimumDistance: e.minimumDistance, ...e.carTypeResponse}
                })

                let currentCarType = this.booking.carType.categoryId.categoryName.toLowerCase();
                this.filterCarOpt = this.carTypes.filter((e) => e.classType === 'first_class' && e.categoryId.categoryName.toLowerCase().includes(currentCarType))[0]

                // check for discount
                let isDiscountValid = false;
                if(this.userId && this.$matchCarForDiscount(this.filterCarOpt.carType)){
                    this.totalTrips = this.booking.additionInfo.discountDetails.totalTrips;
                    isDiscountValid = this.verifyDiscount(this.filterCarOpt.estimatedCost)
                } 
                    
                if(!isDiscountValid) {
                    // normal price in case of trip
                    let tip = (this.filterCarOpt.estimatedCost * 20) / 100;
                    let gratuityPrice = Math.round((parseFloat(tip) + parseFloat(this.filterCarOpt.estimatedCost)));
                    // calculation cleaning fee
                    let cleaningFee = Math.round( (parseInt(gratuityPrice) * parseInt(this.filterCarOpt.categoryId.cleaningPrice)) / 100 );
                    
                    this.recommendedPrice = (parseInt(gratuityPrice) + parseInt(cleaningFee))
                    this.filterCarOpt.tCost = this.recommendedPrice;
                    this.filterCarOpt.cleaningFee = cleaningFee;
                }
                
            }
        },

        verifyDiscount(price){
            if(this.totalTrips === 0){
                let dis = (price * 10) / 100;
                // let tip = 0;
                let tip = (price * 20) / 100;
                let gratuityPrice = Math.round(((parseFloat(price) + parseFloat(tip))));
                let cleaningFee = Math.round( (parseInt(gratuityPrice) * parseInt(this.filterCarOpt.categoryId.cleaningPrice)) / 100 );
                
                this.priceWithTip = Math.round((parseFloat(gratuityPrice) + parseFloat(cleaningFee)))

                this.recommendedPrice = Math.round(((parseFloat(gratuityPrice) + parseFloat(cleaningFee)) - parseFloat(dis)));
                
                this.filterCarOpt.tCost = this.recommendedPrice
                this.filterCarOpt.cleaningFee = cleaningFee

                return true;
            }
            else if(this.totalTrips === 4){
                let dis = 30;
                // let tip = 0;
                let tip = (price * 20) / 100;
                let gratuityPrice = Math.round(((parseFloat(price) + parseFloat(tip))));
                let cleaningFee = Math.round( (parseInt(gratuityPrice) * parseInt(this.filterCarOpt.categoryId.cleaningPrice)) / 100 );

                let afterdis = (parseFloat(price) - parseFloat(dis));
                this.priceWithTip = Math.round((parseFloat(gratuityPrice) + parseFloat(cleaningFee)))
                this.recommendedPrice = Math.round(((parseFloat(gratuityPrice) + parseFloat(cleaningFee)) - parseFloat(dis)));

                if(afterdis <= 0 ){
                    this.recommendedPrice = Math.round(tip + cleaningFee);
                }

                this.filterCarOpt.tCost = this.recommendedPrice;
                this.filterCarOpt.cleaningFee = cleaningFee
                return true;
            } else {
                return false;
            }
        },

        async chooseRecommendedCar() {
            const jsonDataPass = {
                source: {
                startLocationAddr:
                    this.booking.location.pickUpLocation.formattedAddress,
                startLocation: {
                    lat: this.booking.location.pickUpLocation.lat,
                    lng: this.booking.location.pickUpLocation.lng,
                },
                addressComponents: {},
                },
                destination: {
                endLocationAddr:
                    this.booking.location.dropOffLocation.formattedAddress,
                endLocation: {
                    lat: this.booking.location.dropOffLocation.lat,
                    lng: this.booking.location.dropOffLocation.lng,
                },
                addressComponents: {},
                },
                carTypeRequired: this.filterCarOpt._id,
                userId: this.userId,
                isRideMinimum: this.filterCarOpt.isRideMinimum,
                isReturnWay: this.booking.location.isReturnWay
            };

            if (Object.keys(this.booking.location.stopLocation).length) {
                jsonDataPass.stop = {
                stopLocationAddr: this.booking.location.stopLocation.formattedAddress,
                stopLocation: {
                    lat: this.booking.location.stopLocation.lat,
                    lng: this.booking.location.stopLocation.lng,
                },
                addressComponents: {},
                };
            }

            const resp = await request({
                method: "POST",
                url: "/api/v1/trip/estimatedcost",
                data: jsonDataPass,
            });

            if (resp.remote == "success") {
                const estimatedCostData = resp.data;
                estimatedCostData.tCost = this.filterCarOpt.tCost;
                estimatedCostData.cleaningFee = this.filterCarOpt.cleaningFee;

                const additionInfo = {
                    numberPassenger: this.booking.additionInfo.numberPassenger,
                    numberBags: this.booking.additionInfo.numberBags,
                    additionalInfo: this.booking.additionInfo.additionalInfo,
                    isExtraDiscount: this.booking.additionInfo.isExtraDiscount,
                    occasion: this.booking.additionInfo.occasion,
                    discountDetails: {
                        priceWithTip: this.priceWithTip,
                        discountPrice: this.recommendedPrice,
                        totalTrips: this.booking.additionInfo.discountDetails.totalTrips,
                        discountValue: this.booking.additionInfo.discountDetails.discountValue
                    }
                };

                this.$store.commit("setAdditionInfo", additionInfo);

                this.$store.commit("setEstimatesDetails", estimatedCostData);
                this.$store.commit('setSelectedCarType', this.filterCarOpt);
                this.filterCarOpt = '';
            }
        },

        chooseRecommendedHourCar(){
            this.$store.commit("setEstimatesDetails", {tCost: this.filterCarOpt.tCost, cleaningFee: this.filterCarOpt.cleaningFee});
            this.$store.commit('setSelectedCarType', this.filterCarOpt);
            this.filterCarOpt = '';
        },

        async getCarTypes(){
            const resp = await request({
                method: 'get',
                url: `/api/v1/user/cartypes`,
            })
            if (resp.remote === "success") {
                this.carTypes = resp.data;

                let currentCarType = this.booking.carType.categoryId.categoryName.toLowerCase();
                this.filterCarOpt = this.carTypes.filter((e) => e.classType === 'first_class' && e.categoryId.categoryName.toLowerCase().includes(currentCarType))[0]
                
                let price = this.filterCarOpt.pricePerUnitHour * this.booking.location.selectedHour;
                let tip = (price * 20) / 100;
                let costWithGraduation = Math.round((parseFloat(price) + parseFloat(tip)));
                let cleaningFee = Math.round(((costWithGraduation * this.filterCarOpt.categoryId.cleaningPrice) / 100))
                this.recommendedPrice = Math.round((parseFloat(costWithGraduation) + parseFloat(cleaningFee)));
                this.filterCarOpt.tCost = this.recommendedPrice;
                this.filterCarOpt.cleaningFee = cleaningFee;
            }
        },

        async applyCoupon(){
            this.couponError = '';
            if(this.couponCode.trim() == ''){
                this.couponError = "Enter coupon code";
                return false;
            }

            const resp = await request({
                method: 'post',
                url: `/api/v1/promo/validatepromocode`,
                data: {promocode : this.couponCode},
                headers: {
                    authorization: this.userToken,
                    customerid: this.userId
                }
            })
            
            if (resp.remote === "success") {                
                let couponAppliedPrice = '';
                if(resp.data.type == 'Percent'){
                    couponAppliedPrice = Math.round( (parseInt(this.booking.estimates.tCost) * parseInt(resp.data.amount)) / 100 );
                }else{
                    couponAppliedPrice = (this.booking.estimates.tCost - resp.data.amount);
                    if(couponAppliedPrice < 0){
                        couponAppliedPrice = 0;
                    }
                }
                
                this.couponDiscountPrice = couponAppliedPrice;
                this.$store.commit('setCoupon', {coupon: resp.data.promocode, isCouponApplied: true, priceAfterCouponApplied: couponAppliedPrice, couponId: resp.data._id})

            }else {
                this.couponError = resp.errors;
            }
        },
        async removeCoupon(){
            this.couponCode = '';
            this.couponError = '';
            this.couponDiscountPrice = '';
            this.$store.commit('setCoupon', {})
        },
        async applyReferralCode(){
            this.referralError = '';
            if(this.referralCode.trim() == ''){
                this.referralError = "Enter referral code";
                return false;
            }

            const resp = await request({
                method: 'post',
                url: `/api/v1/promo/validateReferralCode`,
                data: {referralCode : this.referralCode},
                headers: {
                    authorization: this.userToken,
                    customerid: this.userId
                }
            })
            
            if (resp.remote === "success") {
                this.$store.commit('setReferral', {referralCode: this.referralCode, applied: true})

            }else {
                this.referralError = resp.errors;
            }
        },
        async removeReferral(){
            this.referralCode = '';
            this.referralError = '';
            this.$store.commit('setReferral', {})
        },
    },
    mounted(){
        if(!this.isLoggedIn){
            this.$router.push({name: 'login'})
            return false;
        }
        if(!this.booking.cardId){
            this.$router.push({name: 'cards'})
            return;
        }

        if(this.booking.location){
            this.pickUpLocation = this.booking.location.pickUpLocation.formattedAddress;
            this.pickUpDate = this.booking.location.pickUpDate;
            this.pickUpTime = this.booking.location.pickUpTime;

            if(this.booking.location.isReturnWay){
                this.returnPickUpDate = this.booking.location.returnPickUpDate;
                this.returnPickUpTime = this.booking.location.returnPickUpTime;
            }

            this.tripType = this.booking.location.type;

            if(this.booking.location.type == process.env.VUE_APP_ONEWAY_TRIP){
                this.dropOffLocation = this.booking.location.dropOffLocation.formattedAddress;
                this.stopLocation = this.booking.location.stopLocation.formattedAddress;
            }else{
                this.selectedHour = this.booking.location.selectedHour;
            }

            if(this.booking.carType.classType == 'standard'){
                
                if (this.booking.location.type == process.env.VUE_APP_HOUR_TRIP) {
                    this.getCarTypes();
                } else {
                    this.getRecomendedCars()
                }
            }
		}

        if(this.coupon){
            this.couponCode = this.coupon.coupon;
        }

        if(this.referral){
            this.referralCode = this.referral.referralCode;
        }
    },
}
</script>

<style scoped>
.stripForm iframe{
    height: 45px !important;
}
.check-out-area{
    padding-top: 0 !important;
}
.car-choose{
    width: 20%;
    margin: auto;
}
.couponForm input{
    width: 20%;
    display: inline-block;
}
.line{
  text-decoration: line-through;
}
</style>
