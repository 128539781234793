<template>
  <div class="container-fluid">
        <!-- Page Heading -->
        <h1 class="h3 mb-2 text-gray-800"> TRIPS</h1>
        <!-- DataTales Example -->
        <div class="card shadow mb-4">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Upcoming Trip Listing</h6>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Invoice</th>
                                <th>Source</th>
                                <th>Destination</th>
                                <th>Status</th>
                                <th>Fare($)</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="userTrips.length">
                            <tr v-for="(trip,index) in userTrips" :key="index">
                                <td>{{trip.readableDate}}</td>
                                <td>{{trip.invoiceNumber}}</td>
                                <td>{{trip.startLocationAddr}}</td>
                                <td>{{trip.endLocationAddr}}</td>
                                <td>{{$root.$options.filters.tripStatus(trip.tripStatus)}}</td>
                                <td>${{Math.round(trip.cost)}}</td>
                                <td>
                                    <router-link v-if="trip.tripStatus === 'FindingDrivers'" :to="{name: 'editTripDetail', params: {tripId: trip._id}}" class="mr-2"><i class="fa fa-pencil da-2x"></i></router-link> 
                                    <router-link :to="{name: 'tripDetail', params: {tripId: trip._id}}"><i class="fa fa-eye da-2x"></i></router-link> 
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="7" class="center">No Record Found.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { request } from "../../utils/api";
import { mapGetters } from "vuex";
export default {
    data(){
        return {
            userTrips: []
        }
    },
    computed:{
		...mapGetters(['booking', 'isLoggedIn', 'userId', 'userToken']),        
	},
    methods:{
        async getCarTypes(){
            const resp = await request({
                method: 'get',
                url: `/api/v1/trip/user/upcoming`,
                headers: {
                    authorization: this.userToken,
                    customerid: this.userId
                }
            })

            if (resp.remote === "success") {
                this.userTrips = resp.data;
            }else{
                
            }
        },
    },
    mounted(){
        this.getCarTypes()
    }
}
</script>

<style>

</style>