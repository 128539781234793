<template>
    <div class="container-fluid">
        <h1 class="h3 mb-2 text-gray-800"> EDIT TRIP DETAILS</h1>
        <div class="card shadow mb-4">
                    <div class="card-header py-3">
                        <h6 class="m-0 font-weight-bold text-primary">TRIP INFORMATION - #{{tripDetail.invoiceNumber}}</h6>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="info_wrp">
                                    <div class="pick-date width2">
                                        <date-picker
                                            v-model="pickUpDate"
                                            format="YYYY-MM-DD"
                                            type="date"
                                            value-type="MM/DD/YYYY"
                                            placeholder="Pick Up Date"
                                            :disabled-date="$disabledBeforeToday"
                                        ></date-picker>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="info_wrp">
                                    <div class="pick-time js width2">
                                        <date-picker
                                            v-if="pickUpTime"
                                            v-model="pickUpTime"
                                            format="hh:mm A"
                                            type="time"
                                            :minute-step="15"
                                            value-type="hh:mm A"
                                            placeholder="Pick Up Time"
                                            :open.sync="openOnewayTimer"
                                            @change="closeTimer"
                                        ></date-picker>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="btn_sec">
                                    <a v-if="tripDetail.tripStatus === 'FindingDrivers'" href="javascript:void(0)" @click="upadteTrip()" class="btn btn-primary btn-icon-split mr-3">
                                        <span class="icon text-white-50">
                                            <i class="fa fa-unlock"></i>
                                        </span>
                                        <span class="text">Update</span>
                                    </a>
                                    <router-link :to="{name: 'trips'}" class="btn btn-info btn-icon-split">
                                        <span class="icon text-white-50">
                                            <i class="fa fa-angle-left"></i>
                                        </span>
                                        <span class="text">Back</span>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
</template>

<script>
import { request } from "../../utils/api";
import { mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/index.css';
export default {
    components:{
        DatePicker
    },
    data(){
        return {
            tripDetail: '',
            tripId: this.$route.params.tripId,
            pickUpDate: '',
            pickUpTime: '',
            openOnewayTimer: false,
        }
    },
    computed:{
		...mapGetters(['booking', 'isLoggedIn', 'userId', 'userToken']),        
	},
    methods:{
        async getCarTypes(){
            const resp = await request({
                method: 'get',
                url: `/api/v1/trip/user/tripdetails/${this.tripId}`,
                headers: {
                    authorization: this.userToken,
                    customerid: this.userId
                }
            })

            if (resp.remote === "success") {
                this.tripDetail = resp.data;
                this.pickUpDate = resp.data.scheduleDate;
                this.pickUpTime = this.$moment(resp.data.scheduleTime, ["h:mm A"]).format("hh:mm A");
                
            }else{
                
            }
        },
        async upadteTrip(){
            if(window.confirm('Are you sure, want to update the trip?')){
                
                if(this.pickUpDate == ""){
                    this.$limotoast.warning('Select Pick Up Date');
                    return;
                }

                if(this.pickUpTime == ""){
                    this.$limotoast.warning('Select Pick Up Time');
                    return;
                }
                
                var pickupDateTime = `${this.pickUpDate} ${this.pickUpTime}`;
                if(this.$moment().isAfter(this.$moment(pickupDateTime))){
                    this.$limotoast.warning('Please select future time!');
                    return false;
                }
                var resultInMinutes = this.$getTimeDifferenceInMinute(pickupDateTime, new Date());
                if (resultInMinutes < 91) {
                    this.$limotoast.warning('You Can Only Book After 90 minutes From Current Time!');
                    return false;
                }
                
                const resp = await request({
                    method: 'post',
                    url: `/api/v1/trip/user/updatetrip`,
                    data: {
                        tripId: this.tripId,
                        scheduleTimezone : "America/Los_Angeles",
                        scheduleDate: this.pickUpDate,
                        scheduleTime: this.pickUpTime,
                    },
                    headers: {
                        authorization: this.userToken,
                        customerid: this.userId
                    }
                })

                if (resp.remote === "success") {
                    this.$router.push({name: 'trips'})
                }else{
                    this.$limotoast.error(resp.errors);
                }
            }
        },
        closeTimer(value, type) {
            if (type === 'ampm') {
                this.openOnewayTimer = false;
            }
        },
    },
    mounted(){
        this.getCarTypes()
    }
}
</script>

<style scoped>
.info_wrp >>> .mx-input-wrapper input, .info_wrp >>> .mx-input-wrapper i, .info_wrp >>> .summary-bar input{
    color: #333 !important;
}

</style>